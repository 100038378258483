import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import GoogleButton from 'react-google-button'
import { Navbar, Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');

  const [companySize, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(true);

  const industries = [
    "Agriculture",
    "Automotive",
    "Construction",
    "Finance",
    "Healthcare",
    "Information Technology",
    "Retail",
    "Transportation",
    "Other"

  ];

  function generatePassword(length = 8) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (isNewUser && email !== confirmEmail) {
      setError("Emails do not match!");
      return;
    }

    try {
      if (isNewUser) {
        // TODO: Send the user details to your backend or Firebase Firestore
        const password = generatePassword(10);  // For a 10-character password

        await createUserWithEmailAndPassword(auth, email, password);

        // ... 
        const db = getFirestore();
        const userRef = doc(db, "users", auth.currentUser.uid);
        await setDoc(userRef, {
          fullName: fullName,
          industry: industry,
          industry: industry,
          email: email,
          companySize: companySize,
          phoneNumber: phoneNumber

          // ... other fields
        });

        // TODO: Send autogenerated password to the provided email
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (



    <div className=" text-dark" style={{ height: '100vh' }}>
      {/* Navigation Bar */}

      {/* Main Content */}

      <section className="jumbotron text-center bg-g1">
        <div className="container">
          <div className="row">
            {/* Left column for text */}
            <h1 className="jumbotron-heading mb-4">Top Shorts, Text to Speech, Voiceover Generator, and Voice Generation Services</h1>


            <div className="col-md-12">


              <p className="lead text-muted">Elevate your presence on YouTube Shorts and TikTok with Faceless Videos crafted by our advanced AI-driven video generator. Create captivating content with personalized graphics and professional-quality AI voiceovers, including Text to Speech services, to truly stand out!</p>
              <p className="lead">Excited to get started? Sign up today to access our innovative platform for free and unleash the potential of AI in your video production!</p>

              <Link className="btn btn-success btn-lg my-2 mb-4" to='https://shorts.doctran.ai/signin'>Start Generating Videos</Link>
            </div>




          </div>
        </div>
      </section>


      <Container className=' mt-4' >


        <div className="row">
          {/* Left column for text */}
          <div className="col-md-6">
            <h1 className="jumbotron-heading">Chat with Your Data <i className="fa-regular fa-comment-dots"></i></h1>
            <h2 className="jumbotron-heading">The Next-Gen Business Intelligence with LLMs</h2>
            <p className="lead text-muted">
              The age of static data analysis is over. At DOCTRAN.ai, we're redefining business intelligence by giving companies the power to converse with their data in real-time. We've synergized the prowess of Large Language Models (LLMs) like ChatGPT with the intrinsic value of company-specific data. The result? Unprecedented, tailored insights delivered conversationally.
            </p>
            <Link className="btn btn-success btn-lg my-2" to='/signin'>Sign up for free</Link>
          </div>

          {/* Right column for YouTube video */}
          <div className="col-md-6">
            {/* Replace 'your-video-id' with the actual YouTube video ID */}
            <div className="embed-responsive embed-responsive-16by9">

              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/kBGXy-TmECs?si=6HxE73juUirfVVw1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
        </div>
        <Row className=''>
          <Col md={3} className='mt-4'>
            <Card className='' >
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body>
                <Card.Title><i className="fa-brands fa-creative-commons-sampling mr-2"></i> Real-time Interactions</Card.Title>
                <Card.Text>
                  No more waiting. Pose questions and get answers instantly.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className='mt-4'>
            <Card >
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body>
                <Card.Title><i className="fa-brands fa-connectdevelop mr-2"></i> Contextual Insights</Card.Title>
                <Card.Text>
                  Answers aren't general; they're based on your unique data.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className='mt-4'>
            <Card >
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body>
                <Card.Title><i className="fa-solid fa-network-wired mr-2"></i> Expansive Search</Card.Title>
                <Card.Text>
                  From specific files to broad datasets, get answers from everywhere.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className='mt-4'>
            <Card >
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body>
                <Card.Title> <i className="fa-brands fa-searchengin mr-2"></i>Transparent Responses</Card.Title>
                <Card.Text>
                  Every response is sourced, ensuring trustworthiness and clarity.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='mt-4 mb-4'>
          {/* Content Section */}
          <Col md={5}>
            <h5>The DOCTRAN Revolution</h5>
            <p className="lead text-muted">
              Gone are the days when data was a one-way stream, confined to sheets and siloed databases. With DOCTRAN.ai, we've turned the tables by allowing businesses to initiate dynamic conversations with their data. Whether it's a financial report from three quarters ago or a market research document drafted yesterday, get answers instantly as if you're chatting with an expert.
            </p>

            <h5>Changing the Data Landscape</h5>
            <p className="lead text-muted">
              In an age where data is often termed as the 'new oil', companies sit atop vast reservoirs of data. Yet, extracting actionable insights remains a challenge. Our platform transforms these challenges into opportunities. By making data interactive and conversational, we're ensuring businesses don't just store data, but actively engage with it.
            </p>
          </Col>
          <Col md={7}>

            <img src="doctran-ai-explainer.png" className="img-fluid" alt="Responsive image"></img>



          </Col>
        </Row>

        <Row>
          <Col md={12}>

          </Col>
        </Row>
      </Container>

      <Container className=' mt-4' >
        <Row>
          {/* Content Section */}
          <Col md={12}>






            <Container className=' mt-4' >
              <Row>
                <h5>Transforming Workflows Across Industries with Doctran.ai Use Cases</h5>
                {/* Content Section */}

                <Col md={3} className='mt-4'>
                  <Card >
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                      <Card.Title>Research Analysts</Card.Title>
                      <Card.Text>
                        Those involved in market research or academic studies can use Doctran's AI to scan through large datasets or lengthy research papers to extract and summarize key findings, trends, or data points.
                      </Card.Text>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className='mt-4'>
                  <Card >
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                      <Card.Title>Lawyers and Legal Assistants</Card.Title>
                      <Card.Text>
                        Lawyers often need to analyze lengthy contracts, litigation documents, or legal briefs. With Doctran's AI solution, they can quickly extract key clauses, important dates, and party details, enabling them to focus more on legal strategy rather than document review.
                      </Card.Text>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className='mt-4'>
                  <Card >
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                      <Card.Title>Case Workers</Card.Title>
                      <Card.Text>
                        Caseworkers, in fields like social work or insurance, need to sift through vast amounts of data to get a holistic view of their client's situation. Our AI can analyze and summarize case notes, reports, and client histories, ensuring that caseworkers have the right information at their fingertips, reducing overheads, and accelerating case resolutions.
                      </Card.Text>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                  </Card>
                </Col>


                <Col md={3} className='mt-4'>
                  <Card >
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                      <Card.Title>Efficient Candidate Shortlisting</Card.Title>
                      <Card.Text>
                        Doctran's AI streamlines recruitment by automating candidate shortlisting. Upon uploading CVs, the AI assesses qualifications and experiences, matching them against job specifications. This process quickly surfaces the most suitable candidates, enabling HR to focus on engagement and selection, ensuring an efficient and unbiased recruitment process.
                      </Card.Text>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                  </Card>
                </Col>






              </Row>
            </Container>




          </Col>




        </Row>
      </Container>








      <Container className='bg-light p-4 mt-4 '>

        <Row className='mt-4'>

          {/* 
          <h5>About us</h5>
          <p className="lead text-muted">Doctran stands out as a pioneering UK-based startup, dedicated to sculpting state-of-the-art AI solutions that are as unique as your business demands. Our cadre of skilled AI architects is on a mission to craft personalized, innovative AI strategies that not only keep you ahead of the curve but also redefine your industry's landscape. Committed to the cutting edge of artificial intelligence, we empower your business operations with the tools to thrive in an AI-centric world. Partner with us, your local yet globally-minded AI ally, and we'll guide you through the intricacies of AI integration. Embark on your AI journey with Doctran. Reach out at info@doctran.ai to explore how we can elevate your business with bespoke AI solutions.</p>
          */}

          <h5>Cutting-Edge AI Offerings</h5>
          <Row className='mt-1'>

            <Col md={3} className='mt-4'>

              <Card>
                <Card.Body>
                  <Card.Title><i className="fa-solid fa-video-camera mr-2"></i> AI Content Generation</Card.Title>
                  <Card.Text>
                    Transform your creative ideas into enchanting video stories with ease. Our innovative platform empowers YouTubers, parents, and authors to craft captivating narratives, complete with unique graphics and immersive audio, </Card.Text>
                  <Button className='btn btn-success btn-sm' variant="primary" href="https://littletales.doctran.ai/" target="_blank" rel="noopener noreferrer">Story / Character for kids</Button>
                  <Button className='btn btn-success btn-sm mt-4' variant="primary" href="https://video-generator.app/" target="_blank" rel="noopener noreferrer">Youtube / TickToc Shorts Generation</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className='mt-4'>

              <Card>
                <Card.Body>
                  <Card.Title><i className="fa-solid fa-magnifying-glass mr-2"></i>  Semantic Search</Card.Title>
                  <Card.Text>
                    Semantic Search goes beyond keyword matching to understand the intent and contextual meaning of words in searchable data. Experience how our solution revolutionizes the way you search, providing relevant results even when exact phrases aren't matched.
                  </Card.Text>
                  <Button className='btn btn-success btn-sm' variant="primary" href="https://cpins-frontend.wittymushroom-7367a36d.uksouth.azurecontainerapps.io/" target="_blank" rel="noopener noreferrer">Semantic Search Demo</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className='mt-4'>
              <Card>
                <Card.Body>
                  <Card.Title><i className="fa-solid fa-database mr-2"></i> Synthetic Data</Card.Title>
                  <Card.Text>
                    Harness the power of AI-generated synthetic data. Our solution produces high-quality, representative, yet completely fictitious datasets that safeguard privacy while still delivering valuable insights. Perfect for testing and development without compromising user data.
                  </Card.Text>
                  <Button className='btn btn-success btn-sm' variant="primary" href="https://synthetic-frontend.wittymushroom-7367a36d.uksouth.azurecontainerapps.io/" target="_blank" rel="noopener noreferrer">Synthetic Data Demo</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className='mt-4'>
              <Card>
                <Card.Body>
                  <Card.Title><i className="fa-solid fa-mobile-button mr-2"></i> AI-Driven Mobile Ecosystem</Card.Title>
                  <Card.Text>
                    Experience the revolution in mobile apps, powered by both AI and cutting-edge Generative AI techniques. Our cross-platform solutions ensure optimal performance, intelligent interactions, and adaptive content generation, no matter the device. Dive into an ecosystem where AI-driven customization meets mobile app excellence.
                  </Card.Text>

                  <a className='btn btn-link btn-sm' variant="primary" href="https://petshome.com/" target="_blank" >Pets Rehome App</a>
                  <a className='btn btn-link btn-sm' variant="primary" href="https://singleparent.app/" target="_blank" >Single Parents</a>
                  <a className='btn btn-link btn-sm' variant="primary" href="https://surrogate.direct/" target="_blank" >Surrogate Direct</a>
                </Card.Body>
              </Card>
            </Col>



          </Row>


        </Row>
      </Container>




      <Navbar bg="dark mt-4" variant="dark">
        <Container>
          <Navbar.Brand href="#home">

            <img src="logo-white.png" width="30" height="30" className="d-inline-block align-top mr-1" alt=" " />
            DOCTRAN.ai
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className='text-light p-2'>
              info@doctran.ai
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </div >

  );

}

export default Home;
